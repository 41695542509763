import Content from './Content'
import Footer from './Footer'
import Header from './Header'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'
import Router from './Router'
import Site from './Site'

const Layout = ({ children }) => (
	<Site>
		<Helmet
			title='Up Up and Away!'
			meta={[
				{
					name: 'description',
					content:
						'Thomas Spanner"\'"s personal wedsite, portfolio, blog, and just cool $h!t',
				},
				{
					name: 'keywords',
					content: 'blog, porfolio, photography, thomas spanner',
				},
			]}
			script={[
				{ src: 'https://use.fontawesome.com/releases/v5.0.4/js/all.js' },
			]}
			link={[
				{
					rel: 'stylesheet',
					href:
						'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
				},
			]}
		/>
		<Header />
		<Content>
			<Router />
			{/* {children()} */}
		</Content>
		<Footer />
	</Site>
)

Layout.propTypes = {
	children: PropTypes.func,
}

export default Layout
