import './index.scss';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from './store';
import { loadBlog } from './store/Blog';
import { loadCv } from './store/Cv';
import { loadGallery } from './store/Gallery';

const store = configureStore();
store.dispatch(loadCv());
store.dispatch(loadBlog());
store.dispatch(loadGallery());

ReactDOM.render(
  <>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
