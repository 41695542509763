import React from 'react'

const StatusTag = ({ status }) => {
	switch (status) {
		case 'DRAFT':
			return (
				<span className='tag is-small is-warning' style={{ marginRight: 20 }}>
					Draft
				</span>
			)
		case 'PUBLISHED':
			return (
				<span className='tag is-small is-success' style={{ marginRight: 20 }}>
					Published
				</span>
			)
		case 'ARCHIVED':
			return (
				<span className='tag is-small is-light' style={{ marginRight: 20 }}>
					Archived
				</span>
			)
		default:
			return <span></span>
	}
}
export default StatusTag
