import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Gallery from "react-photo-gallery";
import Modal from "./modal/Modal";
import PageContent from './components/PageContent';
import PageHeader from './components/PageHeader';
import React from 'react';
import { connect } from "react-redux";
import useModal from './modal/useModal';

const mapStateToProps = state => {
  return {
    photos: state.gallery?.images
  };
};


function Photography(props) {
  const {isShowing, toggle} = useModal();
  const [index, setIndex] = React.useState(0);

  function clickHandler(e, obj) {
    setIndex(obj.index);
    toggle();
  }
  
  return (
    <React.Suspense fallback='Loading gallery...'>
      <PageHeader color="is-info" title="Photography">
        Travel <strong>photographs</strong> taken 2014 - {(new Date().getFullYear())}
      </PageHeader>
      <PageContent>
        <Gallery photos={props.photos} onClick={clickHandler} />
        <hr />
        <h2>
          I sell prints of my favourite photos in A4 or A3 format (Hahnemühle 310 gram fine art
          paper) in my &nbsp;
          <a href="https://www.etsy.com/shop/dayspan" rel="noopener noreferrer" target="_blank">
              <i className="fab fa-etsy"></i>tsy-shop
          </a>
        </h2>
        <Modal
          isShowing={isShowing}
          hide={toggle}
          photos={props.photos}
          index={index}
        />
      </PageContent>
    </React.Suspense>
  )
}

export default connect(mapStateToProps)(Photography);