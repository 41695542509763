import PageContent from './components/PageContent';
import PageHeader from './components/PageHeader';
import React from 'react';

const Imprint = () => (
  <div>
    <PageHeader color="is-info" title="imprint | Impressum"></PageHeader>
    <PageContent>
        <div className="content">
            Thomas Spanner
            <br />
            Müggelstr. 28 · 10247 Berlin
            <br />
            E-Mail: info[at]thomasspanner.de | Internet: www.thomasspanner.de
            <h2>Haftungsbeschränkung</h2>
            <p>Die Inhalte des Internetauftritts wurden mit größtmöglicher Sorgfalt und nach bestem Gewissen erstellt. Dennoch übernehme ich keine Gewähr für die Aktualität, Vollständigkeit und Richtigkeit der bereitgestellten Seiten und Inhalte.</p>
            <h3>Haftung für Links</h3>
            <p>Die Webseite enthält sog. „externe Links“ (Verlinkungen) zu anderen Webseiten, auf deren Inhalt ich keinen Einfluss habe. Trotz sorgfältiger Kontrolle übernehme ich keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
            <h3>Urheberrecht</h3>
            <p>Die auf dieser Webseite veröffentlichten Inhalte unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung, Einspeicherung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Rechteinhabers. Das unerlaubte Kopieren/Speichern der bereitgestellten Informationen auf diesen Webseiten ist nicht gestattet und strafbar.</p>
            <h2>Datenschutz</h2>
            <p>Durch den Besuch des Internetauftritts können Informationen (Datum, Uhrzeit, aufgerufene Seite) über den Zugriff auf dem Server gespeichert werden. Es werden keine personenbezogenen (z. B. Name, Anschrift oder E-Mail-Adresse) Daten, gespeichert. Für den Fall unverlangter Zusendung von Werbeinformationen (z. B. durch Spam-Mails) behalte ich mir rechtliche Schritte vor.</p>
        </div>
    </PageContent>
  </div>
)
export default Imprint