import { CV_LOADING, LOAD_CV_SUCCESS } from './types'

import initialState from '../../store/initialState'

/**
 * CV Reducer
 */
export default function cvReducer(state = initialState.cv, action) {
	switch (action.type) {
		case CV_LOADING:
			return {
				...state,
				loading: action.isLoading,
			}
		case LOAD_CV_SUCCESS:
			return {
				...state,
				cv: action.cv[0],
				loading: false,
			}
		default:
			return state
	}
}
