import { Carousel } from 'react-responsive-carousel'
import React from 'react'

function Modal(props) {
	if (props.isShowing) {
		return (
			<React.Fragment>
				<div className='modal-overlay'></div>
				<div
					className='modal-wrapper'
					aria-modal
					aria-hidden
					tabIndex={-1}
					role='dialog'
				>
					<div className='modal'>
						<div className='modal-header'>
							<button
								type='button'
								className='modal-close-button button-default'
								data-dismiss='modal'
								aria-label='Close'
								onClick={props.hide}
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<Carousel selectedItem={props.index}>
							{props.photos.map((photo) => (
								<div key={props.index}>
									<img alt={photo.alt} src={photo.src} />
									<p className='legend'>{photo.alt}</p>
								</div>
							))}
						</Carousel>
					</div>
				</div>
			</React.Fragment>
		)
	} else {
		return null
	}
}

export default Modal
