export default {
	blog: {
		posts: [],
		loading: false,
	},
	gallery: {
		images: [],
		loading: false,
	},
	cv: {
		cv: null,
		loading: false,
	},
}
