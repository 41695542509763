import * as actions from './cv/actions'
import * as contentful from 'contentful'

const client = contentful.createClient({
	accessToken: '08gd4s4McQvdV2KGWwy6SrhSbfuhcjlHLiRZPmVrJFw',
	space: '4tflcka4vyfn',
})

const error = (err) => console.log(err)

export function loadCv() {
	return (dispatch) =>
		client
			.getEntries({ content_type: 'cv', limit: 1 })
			.then(({ items }) => {
				dispatch(actions.loadCvSuccess(items))
			})
			.catch(error)
}
