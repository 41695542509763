import BlogContent from './BlogContent'
import BlogNav from './BlogNav'
import React from 'react'

const BlogItem = (props) => {
	if (props.isFull) {
		return (
			<div className='box'>
				<BlogContent limit={99999} {...props}>
					<BlogNav date={props.date} status={props.status} to={'/blog'} />
				</BlogContent>
			</div>
		)
	}
	return (
		<div className='box'>
			<BlogContent limit={props.limit} {...props}>
				<BlogNav
					date={props.date}
					status={props.status}
					to={'/blog' + props.path}
				/>
			</BlogContent>
		</div>
	)
}

export default BlogItem
