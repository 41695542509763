import BlogItem from './BlogItem';
import ContentfulService from '../service/ContentfulService';
import PageContent from './../components/PageContent';
import PageHeader from './../components/PageHeader';
import React from 'react';

class BlogDetail extends React.Component { 
  render() {
    return (
      <div>
        <PageHeader color="is-info" title="Travel Blog">
            Your standard <strong>travel blog</strong>, albeit, probably not very good, but at least entertaining!
        </PageHeader>
        <PageContent>
          <ContentfulService query={{ content_type: 'blogPost', limit: 1, order: '-fields.date', 'fields.path': '/' + this.props.match.params.path }}>
              {({ items }) => (
                <div>
                  { items?.map((item, i) => <BlogItem isFull="true" key={i} {...item.fields} />)}
                </div>
              )}
          </ContentfulService>
        </PageContent>
      </div>
    )
  }
}
export default BlogDetail