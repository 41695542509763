/**
 * Blog Reducer
 */

import { BLOG_LOADING, LOAD_BLOG_SUCCESS } from './types'

import initialState from '../../store/initialState'

export default function blogReducer(state = initialState.blog, action) {
	switch (action.type) {
		case BLOG_LOADING:
			return {
				...state,
				loading: action.isLoading,
			}
		case LOAD_BLOG_SUCCESS:
			return {
				...state,
				posts: action.posts,
				loading: false,
			}
		default:
			return state
	}
}
