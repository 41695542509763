import { NavLink } from 'react-router-dom'
import React from 'react'

const Footer = () => (
	<footer className='footer'>
		<div className='container'>
			<div className='content has-text-centered'>
				<p>
					Built on <strong>React</strong> with <strong>Bulma</strong> &{' '}
					<strong>Contentful</strong> by&nbsp;
					<a
						rel='noopener noreferrer'
						target='_blank'
						href='https://github.com/Malaclypse23'
					>
						Thomas Spanner
					</a>
				</p>
				<NavLink className='navbar-item' to='/imprint'>
					Imprint
				</NavLink>
			</div>
		</div>
	</footer>
)

export default Footer
