import Map from './map'
import PageContent from './components/PageContent'
import PageHeader from './components/PageHeader'
import React from 'react'

const Countries = () => (
	<div>
		<PageHeader color='is-info' title='visited countries'>
			<strong>visited countries</strong> 1981 - {new Date().getFullYear()}
		</PageHeader>
		<PageContent>
			<Map />
		</PageContent>
	</div>
)
export default Countries
