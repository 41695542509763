import {
	GoogleMap,
	KmlLayer,
	Marker,
	withGoogleMap,
	withScriptjs,
} from 'react-google-maps'

import React from 'react'

const MyMapComponent = withScriptjs(
	withGoogleMap((props) => (
		<GoogleMap defaultCenter={{ lat: 50.5010789, lng: 4.4764595 }}>
			<KmlLayer
				url='http://www.hirnzweig.de/visited.kml'
				options={{ preserveViewport: false }}
			/>
			{props.isMarkerShown && (
				<Marker position={{ lat: 50.5010789, lng: 4.4764595 }} />
			)}
		</GoogleMap>
	))
)

const Map = (props) => {
	return (
		<div id='map'>
			<MyMapComponent
				className='visited-map'
				minZoom={1}
				isMarkerShown
				googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAshWplRhg0NTStM7zQavJGWdtdp48r0Ws&v=3&libraries=geometry,places'
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `100%` }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		</div>
	)
}
export default Map
