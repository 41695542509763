import { Route, Switch } from 'react-router-dom'

import Blog from './../Blog'
import BlogDetail from '../blog/BlogDetail'
import Countries from '../Countries'
import Home from './../Home'
import Imprint from '../Imprint'
import Photography from '../Photography'
import React from 'react'

const Router = () => (
	<Switch>
		<Route exact path='/' component={Home} />
		<Route exact path='/countries' component={Countries} />
		<Route exact path='/imprint' component={Imprint} />
		<Route exact path='/photography' component={Photography} />
		<Route exact path='/blog' component={Blog} />
		<Route path='/blog/:path' component={BlogDetail} />
	</Switch>
)
export default Router
