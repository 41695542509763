import * as contentful from 'contentful';

import PropTypes from 'prop-types'
import React from 'react';

const client = contentful.createClient({
    space: '4tflcka4vyfn',
    accessToken: '08gd4s4McQvdV2KGWwy6SrhSbfuhcjlHLiRZPmVrJFw'
})
  
export default class ContentfulService extends React.Component {
    static propTypes = {
        query: PropTypes.object.isRequired,
        render: PropTypes.func
    }

    constructor(...args) {
        super(...args)
        this.state = {
        error: null,
        items: [],
        query: this.props.query
        }
    }

    componentDidMount() {
        client.getEntries(this.state.query)
        .then(({ items }) => this.setState({
            items
        }))
        .catch(error => this.setState({
            error
        }))
    }

    render() {
        return this.props.children({
            items: this.state.items,
            error: this.state.error
        })
    }
}