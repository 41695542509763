import './App.scss';

import Layout from './layout/Layout';
import React from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-64394118-1');

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
