/**
 * Store Actions
 */

import * as types from './types'

export function cvLoading(isLoading = true) {
	return { type: types.CV_LOADING, isLoading }
}

export function loadCvSuccess(cv) {
	return { type: types.LOAD_CV_SUCCESS, cv }
}
