import { IMAGES_LOADING, LOAD_IMAGES_SUCCESS } from './types';

import initialState from '../../store/initialState'

/**
* Gallery Reducer
*/
export default function galleryReducer(state = initialState.gallery, action) {
  switch (action.type) {
    case IMAGES_LOADING:
      return {
        ...state,
        loading: action.isLoading
      }
    case LOAD_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.images?.map((item, i) => {
          return { 
            key: i.toString(),
            src: item?.fields?.image?.fields?.file?.url,
            width: item?.fields?.width,
            height: item?.fields?.height,
            alt: item?.fields?.description
          }
        }),
        loading: false
      }
    default:
      return state
  }
}