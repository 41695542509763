import { applyMiddleware, createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { rootReducer } from './rootReducer'
import thunk from 'redux-thunk'

export function configureStore() {
	return createStore(
		rootReducer,
		composeWithDevTools(applyMiddleware(thunk, reduxImmutableStateInvariant()))
	)
}
