/**
 * Store Actions
 */

import * as types from './types'

export function imagesLoading(isLoading = true) {
	return { type: types.IMAGES_LOADING, isLoading }
}

export function loadImagesSuccess(images) {
	return { type: types.LOAD_IMAGES_SUCCESS, images }
}
