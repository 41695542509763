import * as Markdown from 'react-markdown';

import React from 'react'

const BlogContent = (props) => (
  <article className="media blog">
    <div className="media-content">
      <div className="content">
        <h2>{props.title}</h2>
        <h3>{props.subtitle}</h3>
        <figure className="image blog-image">
          <img src={props.image?.fields?.file?.url} alt={props.image?.fields?.description} />
        </figure>
        <Markdown
          className="blog-content"
          source={
            props.limit
            ? props.content?.split(" ").splice(0, props.limit).join(" ").concat('...')
            : props.content
          }
        />
      </div>
      { props.children }
    </div>
  </article>
)
export default BlogContent