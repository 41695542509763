import blogReducer from './blog/reducer'
import { combineReducers } from 'redux'
import cvReducer from './cv/reducer'
import galleryReducer from './gallery/reducer'

export const rootReducer = combineReducers({
	cv: cvReducer,
	blog: blogReducer,
	gallery: galleryReducer,
})
