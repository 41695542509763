import BlogItem from './blog/BlogItem';
import PageContent from './components/PageContent';
import PageHeader from './components/PageHeader';
import React from 'react';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    posts: state.blog?.posts
  };
};

function Blog(props) {
  return (
    <React.Suspense fallback='Loading blog...'>
      <PageHeader color="is-info" title="Up Up & Away">
          Your standard <strong>travel blog</strong>, albeit, probably not very good, but at least entertaining!
      </PageHeader> 
      <PageContent>
      <ul>
        { props.posts?.map((item, i) => <li key={i}>{<BlogItem limit="180" key={i} {...item.fields} />}</li>) }
      </ul>
      </PageContent>
    </React.Suspense>
  )
}

export default connect(mapStateToProps)(Blog);