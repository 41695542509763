import './Header.scss'

import { NavLink } from 'react-router-dom'
import React from 'react'

function Header() {
	const [active, setActive] = React.useState(false)

	function toggleNav() {
		setActive(!active)
	}

	function hideNav() {
		setActive(false)
	}

	return (
		<nav className='navbar' aria-label='main navigation'>
			<div className='navbar-brand'>
				<NavLink className='navbar-item' to='/' activeClassName='is-active'>
					<img src='/assets/certificate.png' alt='about' />
					<span>thomasspanner.de</span>
				</NavLink>
				<button
					className='button navbar-burger'
					aria-label='mobile-menu'
					onClick={toggleNav}
				>
					<span></span>
					<span></span>
					<span></span>
				</button>
			</div>
			<div className={active ? 'navbar-menu is-active' : 'navbar-menu'}>
				<div className='navbar-start'>
					<NavLink className='navbar-item' to='/blog' onClick={hideNav}>
						<img src='/assets/travel.png' alt='travel blog' />
						<span>Travel Blog</span>
					</NavLink>
					<NavLink className='navbar-item' to='/photography' onClick={hideNav}>
						<img src='/assets/cow-30px.jpg' alt='photography' />
						<span>Photography</span>
					</NavLink>
					<a
						className='navbar-item'
						rel='noopener noreferrer'
						target='_blank'
						href='https://www.etsy.com/shop/dayspan'
					>
						<i className='fab fa-etsy'></i>
						<span>tsy-Shop</span>
					</a>
					{/*}
					<div className='navbar-item has-dropdown is-hoverable'>
						<span className='navbar-link'>Traveling</span>
						<div className='navbar-dropdown'>
							<NavLink
								className='navbar-item'
								to='/blog/traveling-on-a-budget'
								onClick={hideNav}
							>
								Traveling on a budget
							</NavLink>
							<hr className='navbar-divider' />
							<NavLink
								className='navbar-item'
								to='/blog/luggage-check'
								onClick={hideNav}
							>
								What to bring
							</NavLink>
							<hr className='navbar-divider' />
							<NavLink
								className='navbar-item'
								to='/countries'
								onClick={hideNav}
							>
								Visited countries
							</NavLink>
						</div>
					</div>
					*/}
				</div>
				<div className='navbar-end'>
					<a className='navbar-item' href='https://github.com/Malaclypse23'>
						<span className='icon'>
							<i className='fab fa-lg fa-github'></i>
						</span>
					</a>
					<a className='navbar-item' href='https://twitter.com/Tomdoom'>
						<span className='icon has-text-info'>
							<i className='fab fa-lg fa-twitter'></i>
						</span>
					</a>
					<a
						className='navbar-item'
						href='https://www.xing.com/profile/Thomas_Spanner3/cv'
					>
						<span className='icon'>
							<img className='xing' src='/assets/xing.png' alt='XING' />
						</span>
					</a>
				</div>
			</div>
		</nav>
	)
}

export default Header
